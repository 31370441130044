import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SEO from "../components/seo";
import AppButton from "../components/common/button";
import TextInput from "../components/inputs/textInput";
import {
	changeProposeRestaurantValueAction,
	clearProposeRestaurantFormAction,
	sendProposeRestaurantEmailAction,
} from "../state/proposeRestaurant.reducer";
import { Loading } from "../components/common/loading";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import { AppContainer } from "../components/common/appContainer";

const StyledFormWrpaper = styled.div`
	text-align: center;
`;
const StyledInputWrapper = styled.div`
	margin: 0;
`;
const StyledButtonWrapper = styled.div`
	padding: 15px 0 45px;
	text-align: center;
`;

const ProposeRestaurantPage = () => {
	const dispatch = useDispatch();
	const proposeRestaurant = useSelector((state) => state.proposeRestaurant);
	const { t } = useTranslation();
	const { city, name, loading } = proposeRestaurant;
	const formValid = city.length > 0 && name.length > 0 && !loading;

	useEffect(() => {
		dispatch(clearProposeRestaurantFormAction());
	}, []);

	const handleChange = (name) => (value) => {
		dispatch(changeProposeRestaurantValueAction(name, value));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(sendProposeRestaurantEmailAction(name, city));
	};

	return (
		<>
			<SEO title={t("proposeRestaurant.title")} />
			<AppContainer>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<StyledFormWrpaper>
						<AppPageHeader>{t("proposeRestaurant.header")}</AppPageHeader>
						<AppLeadingText>{t("proposeRestaurant.description")}</AppLeadingText>
						<StyledInputWrapper>
							<TextInput
								autoComplete="off"
								error={!name}
								errorMsg={t("proposeRestaurant.nameInputErrorMsg")}
								id="name"
								inputChanged={handleChange("name")}
								placeholder={t("proposeRestaurant.nameInputPlaceholder")}
								required
								type="text"
								value={name}
							/>
						</StyledInputWrapper>
						<StyledInputWrapper>
							<TextInput
								autoComplete="off"
								error={!city}
								errorMsg={t("proposeRestaurant.cityInputErrorMsg")}
								id="city"
								inputChanged={handleChange("city")}
								placeholder={t("proposeRestaurant.cityInputPlaceholder")}
								required
								value={city}
							/>
						</StyledInputWrapper>
					</StyledFormWrpaper>
					<StyledButtonWrapper>
						<AppButton disabled={!formValid}>{t("common.send")}</AppButton>
					</StyledButtonWrapper>
				</form>
				{loading && <Loading text={t("proposeRestaurant.loading")} />}
			</AppContainer>
		</>
	);
};

export default ProposeRestaurantPage;
